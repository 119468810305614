.profile-top-section{
    height:400px;
    background:black;
    position:absolute;

}
.subscription-button {
    margin-top:10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        /* background-color: #ffffff; */
        color: rgb(0, 0, 0);
        border: 1px solid grey;
        border-radius: 30px;
        font-size: 16px;
    }
    .subscription-button .left-content {
        display: flex;
        align-items: center;
    }
    .subscription-button .left-content i {
        margin-right: 8px;
    }
    .profile-btn{
        background:white;
        padding:20px;
        border-radius:30px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .fas{
        color:#d2ab67;
    }
    .user-profile-section{
        background: white;
        border-radius:30px;
        padding:20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .user-header{
        display:flex;
        justify-content: space-between;
    }
    .theme-color{
        color:#d2ab67 !important;
    }
    .btn-save{
        border:1px solid #d2ab67;
        border-radius:30px;
    }
    .btn-save:hover{
        background:#d2ab67;
        border-radius:30px;
    }
    .form-control{
        border-radius: 30px !important;
        font-size:13px;
    }
    .user-image{
        display:flex;
        justify-content: center;
        align-items:center;
        flex-direction: column;
    }
    .user-select-btn{
        display: flex;
        justify-content: space-between;
    }
    .delivery-details{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius:30px;
        background: white;
        padding:10px;
    }
.delivery-data{
display:flex;
background: #d2ab67;
color:white;
border-radius:30px;
padding:10px;

}

.number-badge {
    font-size: 1rem; /* Adjust the font size as needed */
    padding: 0.5em 0.75em; /* Adjust padding for better alignment */
    margin: 0.2em; /* Add some margin for spacing between badges */
    background-color: #d2ab67 !important;
    background: #d2ab67 !important; /* Change background color */
    color: white; /* Change text color */
    border-radius: 50%; /* Make the badge circular */
    display: inline-block; /* Ensure badges are inline */
}