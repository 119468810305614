/* @font-face
{
    font-family: myCustomFont;
     src:("./font/Hurme Geometric Sans Family/HurmeGeometricSans1.otf");
} */


.cards {
    margin: 0 auto;
    text-align: center;
      display: -webkit-flex;
      display: flex;
    border-radius: 10px;
      -webkit-justify-content: center; 
    justify-content: center;
    -webkit-flex-wrap: wrap; 
      flex-wrap: wrap;
      margin-top: 15px;
      padding: 1.5%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  
  .cards:hover {
    box-shadow: 0 4px 10px rgba(0,0,0,0.16), 0 4px 10px rgba(0,0,0,0.23);
  }

  .custom-header {
    background-color: #e1a95f; /* Golden background color */
    color: white;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}


.custom-header h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}