 
  h1 {
    font-size: 16px;
    margin: 5px;
  }
  .cart-container {
    width: 100%;
    height: 92vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #f9fafc;
  }
  
  /*
  Payment Information card
  */
  .details_card {
    width: 700px;
    height: 400px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
  }
  
  button .btn .recharge-btn{
    background-color: #d2ab67 !important;
    color: white !important;
    border: none;
    border-radius: 20px !important;
    padding: 10px 20px !important;
}
.Subscription-section-new{
  border: 1px solid #d2ab67;
  padding:10px;
  margin:10px;
}
  /*
  Order Summary Card
  */
  .summary_card {
    background-color: #fff;
    width: 450px;
    
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 7px;
  }
  
  /*
  Order Card Item Card
  */
  .card_item {
    display: flex;
    position: relative;
    margin: 10px 0;
  }
  .close-btn {
    position: absolute;
    right: 10px;
    top: 5px;
  }
  
  .card_item .product_img img {
    height: 80px;
    margin-right: 20px;
    width: 70px;
    border-radius: 5px;
  }
  
  .product_info h1 {
    font-size: 20px;
    color: #1e212d;
    margin: 5px 0;
  }
  
  .product_info p {
    color: #a1a1a1;
    font-size: 14px;
  }
  .product_rate_info {
    display: flex;
    margin: 5px 0;
    align-items: center;
    justify-content: space-between;
  }
  
  /*
  cart item + & -
  */
  .pqt {
    font-size: 20px;
    line-height: 30px;
    width: 30px;
    text-align: center;
  }
  
  .pqt-plus,
  .pqt-minus {
    background: #fcfcfc;
    border: none;
    font-size: 20px;
    height: 100%;
    padding: 0 15px;
  }
  .pqt-plus:hover,
  .pqt-minus:hover {
    background: #53b5aa;
    color: #fff;
    cursor: pointer;
  }
  
  .pqt-plus {
    line-height: 30px;
  }
  
  .pqt-minus {
    line-height: 30px;
  }
  /*
  Order price & total
  */
  .order_price,
  .order_service,
  .order_total {
    display: flex;
  
    justify-content: space-between;
    padding: 10px;
  }
  .order_price p,
  .order_service p {
    color: #a1a1a1;
  }
  
  .order_total p {
    font-weight: 600;
  }
  
  /*
  Payment Information all input
  */
  
  input {
    outline: 0;
    background: #f2f2f2;
    border-radius: 4px;
    width: 100%;
    border: 0;
    caret-color: #4f5d7e;
    margin: 10px 15px;
    padding: 15px 20px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  .first_lastName,
  .shipping_card,
  .address {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }
  
  .new_card {
    width: 100%;
    height: 100px;
    border: 2px solid #53b5aa;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
  }
  
  .add_savedcard {
    width: 100%;
    height: 100px;
    border: 2px solid #a1a1a1;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
  }
  /*
  Procced Payment button
  */
  .proced_payment a {
    padding: 10px 20px;
    width: 200px;
    border: 2px solid #f5f5f5;
    background-color: #53b5aa;
    color: #000;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
  }
  