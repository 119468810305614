
.faq-section{
    border:1px solid grey;
    border-radius:30px;
    padding:20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.eight-section-faq {
    background-color: #f8f4ec;
    padding-top: 50px;
    padding-bottom: 100px;
  }
  .eight-section-faq .accordion-item {
    margin-bottom: 22px;
    /* padding: 11px; */
    border-radius: 40px;
    border: none;
}
.accordion-body{
    font-size:14px;
    padding:20px;
}
  .eight-section-faq .accordion-item .accordion-header .accordion-button{
     /* font-size: 30px;*/
     padding:10px;
      font-size: 13px;
      font-weight: 600;
      color: #000;
      background-color: transparent;
  }
  .eight-section-faq .accordion-item .accordion-header .accordion-button:focus{
      box-shadow: none;
      outline: 0px;
  }
  .eight-section-faq .accordion-header .accordion-button::before {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    background-color: #000;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-right: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
  .eight-section-faq .accordion-header .accordion-button.collapsed::before{
      content: "\2b";
  }
  .eight-section-faq .accordion-header .accordion-button::after{
      display: none;
  }
  