.theme-color{
    color:#d2ab67;
}
.product-item-image{
    width:100%;
    max-width:190px;
    margin:0 auto;
}

.product-banner{
    position: relative;
    text-align: center;
    color: white;
    text-transform: uppercase;
    background-size: cover;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('images/product-banner.png') no-repeat center center;
    background-size: cover;
    /* background-image: url('/images/product-banner.jpg'); */
}
.btn:hover{
    background: #d2ab67 !important;
    color:white !important;
}
.product-details{
    display:flex;
    justify-content: space-around;
}
h5{
    font-size:13px;
    font-weight: 700;
}
p{
    font-size:13px;   
}
.add-btn{
    font-size:13px;
    font-weight: 700;
}
.card{
    padding:10px;
    border-radius: 30px !important;
    /* background: #fcf2f2 !important; */
    border: 1.5px solid #d2ab67 !important;
    margin-top:20px;
}
.btn-light {
border: 1px solid #d2ab67 !important;
color: #d2ab67 !important;
border-radius:30px;
}
.card{
line-height:15px;
}
.item-2{
font-size:13px;
}
.product-page{
    /* margin-top:100px; */
    margin-bottom:100px;
}